import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../components/layout"
import VideoCover from "../components/video-cover"
import ImageCover from "../components/image-cover"
import Realisation from "../components/realisation"
import LastRealisation from "../components/last-realisation"
import SEO from "../components/seo"

// import scrollJacker from "../scripts/helpers"
// import cssStyle from "../styles/pagepiling.css"

class WebMarketing extends React.Component {
  render = () => (
    
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <main id="wall">
          
          { /* <section className="scroll-section"> */}
            <VideoCover
              title="Stratégies digitales"
              videoUrl="/video/test.mp4" 
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover 
              title="SEO - Referencement Organique"
              content="Être en première page de Google est primordial pour votre activité, mais dompter le moteur de recherche n’est pas chose aisée et demande des compétences aiguisées. UTOPIE renverse les codes et vous aide au référencement et suivi de positionnement de votre site Internet."
              image="/img/hero/seo1.jpg"
              
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Publicité sur les moteurs de recherche"
              content="Valoriser un lien sponsorisé dans la liste des résultats proposés par un moteur de recherche permet de gagner rapidement un fort trafic qualifié. l'interface de Google ADS est simple, mais assurer un ROI positif dépand de plusieurs détails. UTOPIE vous accompagne dans le Choix des mots clés, le Ciblage, le Paramètrage, l'Optimisation, et le Reporting."
              image="/img/hero/sea.jpg"
              
            />
          {/* </section> */}

          { /* <section className="scroll-section"> */}
            <ImageCover
              title="Publicité sur les médias sociaux"
              content="Presque tout le monde est sur facebook, facebook ads coûte moins cher, le cibler d'une audience se fait avec une extrême précision, les performances des publicités facebook sur mobile sont excellentes. Une présence sur les réseaux sociaux va augmenter la notoriété, générer du trafic et influencer meme le positionnement dans les résultats de recherche de google!"
              image="/img/hero/social.jpg"
            />
          {/* </section> */}

          <LastRealisation />

          <Realisation />

        </main>
      </Layout>
    
  )

  constructor(props) {
    super(props);
    this.state = {
      wall: null
    }
  }

  componentDidMount = () => {
    // helpers.loadCSS(cssStyle, "#pagepilling")
  }

  componentWillUnmount = () => {
    // helpers.unloadCSS("#pagepilling")
  }

}

export default WebMarketing
